import React from 'react';
// import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { Container, Row } from 'react-grid-system';
// import Title from 'components/title';
import Component from '@reach/component-component';
import { Dialog } from '@reach/dialog';
import VisuallyHidden from '@reach/visually-hidden';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
// import BgImage from 'assets/images/work/amex/amex_bell.png';
import BgImage from 'assets/images/work/amex/amex-booth.jpg';
import ModalThumb from 'assets/images/work/amex/american-express.jpg';
import ModalImage01 from 'assets/images/work/amex/american-express.jpg';
import ModalImage02 from 'assets/images/work/amex/amex-booth.jpg';
import ModalImage03 from 'assets/images/work/amex/amex_play.jpg';
import ModalImage04 from 'assets/images/work/amex/amex-nyc.jpg';

export const Wrapper = styled.div`
  margin-top: -4rem;
  ${MEDIA.TABLET`
    display: block;
  `};
`;

export const SectionWrapper = styled.section`
  .section__img-inner {
    background-image: url(${BgImage});
    background-position: 50% 0%;
  }
  ${MEDIA.TABLET`
    display: block;
    .section__img-inner {
      background-image: url(${BgImage});
      background-position: 0% 0%;
    }
  `};
`;

const ImageModal = () => (
  <Component initialState={{ showDialog: false }}>
    {({ state, setState }) => (
      <div>
        <button onClick={() => setState({ showDialog: true })}>
          <img src={ModalThumb} alt="American Express Thumbnail" />
        </button>

        <Dialog
          isOpen={state.showDialog}
          onDismiss={() => setState({ showDialog: false })}
        >
          <button
            className="close-button"
            onClick={() => setState({ showDialog: false })}
          >
            <VisuallyHidden>Close</VisuallyHidden>
            <span aria-hidden>×</span>
          </button>
          <p style={{ width: '100%' }}>
            <img
              style={{ width: '100%', marginBottom: '1rem' }}
              src={ModalImage01}
              alt="Flight Simulator"
            />
            <img
              style={{ width: '100%', marginBottom: '1rem' }}
              src={ModalImage02}
              alt="Booth"
            />
            <img
              style={{ width: '100%', marginBottom: '1rem' }}
              src={ModalImage03}
              alt="Play"
            />
            <img
              style={{ width: '100%', marginBottom: '1rem' }}
              src={ModalImage04}
              alt="NYC"
            />
          </p>
        </Dialog>
      </div>
    )}
  </Component>
);

const Section = () => (
  <SectionWrapper className="section section--current">
    <div className="section__content">
      <h1 className="section__title">Flight Simulator</h1>
      <p className="section__description">
        <span className="section__description-inner">
          Trade in Cool designed, programmed, and launched this 3D flight
          simulator for American Express. Working alongside UCG Marketing, we
          deployed these kiosks in 2 airports across the U.S. The challenge of
          the campaign was to achieve the acquisition and retention of new
          customers and American Express wanted to get the word out about the
          cards valuable rewards and benefits. To achieve this end we created a
          3 minute flight across a virtual landscape of rewards. This kiosk a
          successful and fun way to capture the attention and imagination of of
          the public at large and collect valuable user data. Even airline
          pilots couldnt resist playing!
        </span>
      </p>
    </div>
    <div className="section__img">
      <div className="section__img-inner" />
    </div>
    <div className="section__more">
      <div className="section__more-inner section__more-inner--bg1">
        <span className="section__more-text">Want to know more?</span>
        <a href="/work" className="section__more-link">
          <span className="section__more-linktext">Explore all projects</span>
        </a>
      </div>
    </div>
    <ul className="section__facts">
      <li
        className="section__facts-item section__facts-item--clickable"
        data-gallery="gallery1"
      >
        <div className="section__facts-img">
          <ImageModal />
        </div>
        <h2 className="section__facts-title">American Express</h2>
      </li>
      <li className="section__facts-item">
        <h3 className="section__facts-title">Kiosk</h3>
        <span className="section__facts-detail">
          Design, Development, 3D Modeling
        </span>
      </li>
    </ul>
  </SectionWrapper>
);

const Alice = () => (
  <Layout>
    <Container fluid>
      <Row>
        <Wrapper className="sections">
          {/* <Fact /> */}
          <Section />
        </Wrapper>
      </Row>
    </Container>
  </Layout>
);

export default Alice;
